import React from 'react';
import {SlotCard} from './SlotCard';

export const SlotList = ({slots}) => {
    return (
        <div className="container">
            <div className="row">
                {slots.map(slot => (
                    <div key={slot.id} className="col-sm-6 col-md-4 col-lg-3 mb-4">
                        <SlotCard slot={slot} />
                    </div>
                ))}
            </div>
        </div>
    )
};