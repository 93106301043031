import {NavLink} from 'react-router-dom';
import {useContext} from 'react';
import {AuthContext} from '../context/AuthContex';

export const Navbar = () => {
    const auth = useContext(AuthContext);
    const logoutHandler = event => {
        event.preventDefault();
        auth.logout();
    };
    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid">
                <a className="navbar-brand" href="/">Slots Stand</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <NavLink to={'/catalog'} className="nav-link">Slots</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/providers'} className="nav-link">Providers</NavLink>
                        </li>
                        {/*<li className="nav-item">*/}
                        {/*    <NavLink to={'/slot/'} className="nav-link">Slot</NavLink>*/}
                        {/*</li>*/}
                        {auth.isAdmin &&
                            <li className="nav-item">
                                <NavLink to={'/admin'} className="nav-link">Admin</NavLink>
                            </li>
                        }

                        <li className="nav-item">
                            <a className="nav-link" aria-disabled="true" href="/" onClick={logoutHandler}>Logout</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};