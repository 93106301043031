import React from 'react';
import {useRoutes} from './routes';
import {BrowserRouter as Router} from 'react-router-dom';
import {useAuth} from './hooks/auth.hook';
import {AuthContext} from './context/AuthContex';
import {Navbar} from './components/Navbar';

function App() {
    const {token, login, logout, userId, role, api_key} = useAuth();
    const isAuthenticated = !!token;
    const isAdmin = role === 'Admin';
    const routes = useRoutes(isAuthenticated, isAdmin);

    return (
        <AuthContext.Provider value={{token, login, logout, userId, isAuthenticated, isAdmin, role, api_key}}>
            <div className='container'>
                <Router>
                    {isAuthenticated && <Navbar/>}
                    {routes}
                </Router>
            </div>
        </AuthContext.Provider>
    );
}

export default App;
