import { useState, useCallback, useEffect } from 'react';
export const useAuth = () => {
    const [token, setToken] = useState(null);
    const [userId, setUserId] = useState(null);
    const [role, setRole] = useState(null);
    const [api_key, setApiKey] = useState(null);

    const login = useCallback((jwtToken, id, role, api_key) => {
        setToken(jwtToken);
        setUserId(id);
        setRole(role);
        setApiKey(api_key);
        localStorage.setItem('userData', JSON.stringify({ userId: id, token: jwtToken, role: role, api_key: api_key }));
    }, []);

    const logout = useCallback(() => {
        setToken(null);
        setUserId(null);
        setRole(null);
        setApiKey(null);
        localStorage.removeItem('userData');
    }, []);

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('userData'));
        if (data && data.token) {
            login(data.token, data.userId, data.role, data.api_key);
        }
    }, [login]);

    return { login, logout, token, userId, role, api_key };
};