import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import {SlotsCatalog} from './pages/SlotsCatalog';
import {SlotPage} from './pages/SlotPage';
import {AuthPage} from './pages/AuthPage';
import {AdminPage} from './pages/AdminPage';
import {ProvidersCatalog} from './pages/ProvidersCatalog';
export const useRoutes = (isAuthenticated, isAdmin) => {
    return (
        <Routes>
            <Route path='/catalog' exact element={isAuthenticated ? <SlotsCatalog/> : <AuthPage/>}/>
            <Route path='/providers' exact element={isAuthenticated ? <ProvidersCatalog/> : <AuthPage/>}/>
            <Route path='/admin' exact element={isAuthenticated && isAdmin ? <AdminPage/> : <Navigate to="/catalog" />}/>
            <Route path='/' exact element={isAuthenticated ? <Navigate to="/catalog" /> : <AuthPage/>}/>
            <Route path='/slot/*' exact element={isAuthenticated ? <SlotPage/> : <AuthPage/>}/>
            <Route path='*' element={isAuthenticated ? <Navigate to="/catalog" /> : <AuthPage />} />
        </Routes>
    )

};