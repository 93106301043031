import React, {Fragment, useContext, useEffect, useState} from 'react';
import {useHttp} from '../hooks/http.hook';
import {useMessage} from '../hooks/message.hook';
import {AuthContext} from '../context/AuthContex';

export const AdminPage = () => {
    const message = useMessage();
    const auth = useContext(AuthContext);
    const {loading, error, request, clearError} = useHttp();
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('User'); // default role is 'User'
    const [password, setPassword] = useState('');

    useEffect(() => {
        if (error) message(error);
        clearError();
    }, [error, message, clearError]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const data = await request('/api/auth/register', 'POST', {email, password, role, token: auth.token});
            message(data.message);
        } catch (e) {}

    };
  return (
    <Fragment>
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title text-center">Register new user</h3>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="email">Email address</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="role">Role</label>
                                    <select
                                        className="form-control"
                                        id="role"
                                        value={role}
                                        onChange={(e) => setRole(e.target.value)}
                                    >
                                        <option value="User">User</option>
                                        <option value="Admin">Admin</option>
                                    </select>
                                </div>
                                <button type="submit" className="btn btn-primary btn-block mt-3" disabled={loading}>Register</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
  );
}