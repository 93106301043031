import React, {useContext} from 'react';
import {AuthContext} from '../context/AuthContex';

export const SlotCard = ({ slot }) => {
    const {api_key} = useContext(AuthContext);
    return (
        <div className="card h-100 d-flex flex-column">
            <img src={slot.thumb} className="card-img-top" alt={slot.name} />
            <div className="card-body d-flex flex-column">
                <h5 className="card-title">{slot.name}</h5>
                <h6 className="card-subtitle mb-2 text-muted">Provider: {slot.provider}</h6>
                <p className="card-text">Type: {slot.type}</p>
                <div className="mt-auto">
                    <a href={`slot/${encodeURIComponent(slot.url)}?token=${api_key}`} className="btn btn-primary" rel="noopener noreferrer">Learn More</a>
                </div>
            </div>
        </div>
    );
};