import React, {Fragment} from 'react';
import {useLocation} from 'react-router-dom';

export const SlotPage = () => {
    const location = useLocation();
    const urlEncoded = location.pathname.replace('/slot/', ''); // Извлекаем URL из пути
    const url = decodeURIComponent(urlEncoded); // Декодируем URL
    const search = location.search; // Извлекаем строку запроса
    const resultUrl = url + search; // Склеиваем URL и строку запроса
    return (
        <Fragment>
            <iframe
                src={resultUrl}
                width="100%"
                height="600px"
            />
        </Fragment>
    );
}