import {createContext} from 'react';

export const AuthContext = createContext({
    token: null,
    userId: null,
    login: () => {},
    logout: () => {},
    isAuthenticated: false,
    isAdmin: false,
    role: null,
    api_key: null
});