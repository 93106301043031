import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import {AuthContext} from '../context/AuthContex';
import {useHttp} from '../hooks/http.hook';
import {Pagination} from 'react-bootstrap';
import {SlotList} from '../components/SlotList';
import {ProvidersList} from '../components/ProvidersList';

export const ProvidersCatalog = () => {
    const { api_key } = useContext(AuthContext);
    const [providers, setProviders] = useState([]);
    const [meta, setMeta] = useState({ current_page: 1, last_page: 1 });
    // const [cache, setCache] = useState({});

    const { request } = useHttp();

    const fetchProviders = useCallback(async (page = 1) => {
        try {
            const headers = new Headers({
                "Content-Type": "application/json",
                "Origin": window.location.origin,
                "Accept": "application/json"
            });

            const fetched = await request(`https://slotslaunch.com/api/providers?token=${api_key}&page=${page}`, 'GET', null, headers);
            // console.log(fetched);

            if (!fetched.data || !fetched.meta) {
                console.error('Failed to fetch providers:', fetched);
                throw new Error('Failed to fetch providers');
                return;
            }

            setProviders(fetched.data);
            setMeta(fetched.meta);

            sessionStorage.setItem('providers', JSON.stringify(fetched.data));
            sessionStorage.setItem('meta_providers', JSON.stringify(fetched.meta));
        } catch (e) {
            console.error('Failed to fetch slots:', e);
        }
    }, [request, api_key/*, cache*/]);

    useEffect(() => {
        const savedProviders = sessionStorage.getItem('providers');
        const savedMeta = sessionStorage.getItem('meta_providers');
        // const savedCache = sessionStorage.getItem('cache');

        if (savedProviders && savedMeta) {
            setProviders(JSON.parse(savedProviders));
            setMeta(JSON.parse(savedMeta));
        } else {
            fetchProviders(meta.current_page);
        }
    }, []); // Note the empty dependency array here

    const handlePageChange = (pageNumber) => {
        setMeta((prevMeta) => ({ ...prevMeta, current_page: pageNumber }));
        fetchProviders(pageNumber);
    };

    const renderPaginationItems = () => {
        const items = [];
        const maxItemsToShow = 5;
        const { current_page, last_page } = meta;

        let startPage = Math.max(1, current_page - Math.floor(maxItemsToShow / 2));
        let endPage = startPage + maxItemsToShow - 1;

        if (endPage > last_page) {
            endPage = last_page;
            startPage = Math.max(1, endPage - maxItemsToShow + 1);
        }

        for (let page = startPage; page <= endPage; page++) {
            items.push(
                <Pagination.Item
                    key={page}
                    active={page === current_page}
                    onClick={() => handlePageChange(page)}
                >
                    {page}
                </Pagination.Item>
            );
        }

        return items;
    };

    return (
        <Fragment>
            <Pagination>
                <Pagination.First onClick={() => handlePageChange(1)} disabled={meta.current_page === 1} />
                <Pagination.Prev onClick={() => handlePageChange(meta.current_page - 1)} disabled={meta.current_page === 1} />
                {renderPaginationItems()}
                <Pagination.Next onClick={() => handlePageChange(meta.current_page + 1)} disabled={meta.current_page === meta.last_page} />
                <Pagination.Last onClick={() => handlePageChange(meta.last_page)} disabled={meta.current_page === meta.last_page} />
            </Pagination>
            <ProvidersList providers={providers} />
        </Fragment>
    );
};