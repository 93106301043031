import { useCallback } from 'react';
import {Toast} from 'bootstrap';
export const useMessage = () => {
    function showToast(message) {
        // Создаем элемент toast
        const toastEl = document.createElement('div');
        toastEl.className = 'toast';
        toastEl.role = 'alert';
        toastEl.ariaLive = 'assertive';
        toastEl.ariaAtomic = 'true';

        // Добавляем разметку для toast
        toastEl.innerHTML = `
        <div class="toast-header">
            <strong class="me-auto">Message</strong>
            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
            ${message}
        </div>
    `;

        // Добавляем элемент toast в контейнер
        document.body.appendChild(toastEl);

        // Создаем и отображаем toast
        const toast = new Toast(toastEl);
        toast.show();

        // Удаляем элемент toast после его скрытия
        toastEl.addEventListener('hidden.bs.toast', function () {
            document.body.removeChild(toastEl);
        });
    }
    return useCallback((text) => {
        if (!text) return;
        showToast(text);
    }, []);
};