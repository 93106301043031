import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

export const ProviderCard = ({ provider }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="card h-100 d-flex flex-column">
                <img src={provider.thumb} className="card-img-top" alt={provider.name} />
                <div className="card-body d-flex flex-column flex-grow-1">
                    <h5 className="card-title">{provider.name}</h5>
                </div>
                <div className="card-footer mt-auto">
                    {provider.bio && (
                        <Button variant="primary" onClick={handleShow} className="w-100">
                            Read More
                        </Button>
                    )}
                </div>
            </div>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{provider.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="provider-bio">
                        <p>{provider.bio}</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
