import React from 'react';
import {SlotCard} from './SlotCard';
import {ProviderCard} from './ProviderCard';

export const ProvidersList = ({providers}) => {
    return (
        <div className="container">
            <div className="row">
                {providers.map(provider => (
                    <div key={provider.id} className="col-sm-6 col-md-4 col-lg-3 mb-4">
                        <ProviderCard provider={provider} />
                    </div>
                ))}
            </div>
        </div>
    )
};